import {Helmet} from "react-helmet"
import shareImg from "../assets/images/meta/share.png"
import Background from "../components/Background/Background"
import * as React from "react"

const PrivacyPage = ({location}) => {
    return (
        <main>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Privacy | Nocturne X</title>
                <meta name="description"
                      content="Enter an otherwordly forest of sound and light on planet Nocturne X in this transformative multi-sensory art experience, where the alien environment reacts to your every touch and movement."/>
                <meta property="og:image" content={shareImg}/>
                <link rel="canonical" href="https://nocturne-x.com"/>
            </Helmet>

            <Background/>

            <div className="Section" id="explore">
                <div className="Container">
                    <div className="Container__wrapper">
                        <h2 className="Text--preheading Text--teal">
                            Last modified: August 2021
                        </h2>
                        <h1 className="Text--heading Text--teal">
                            Privacy Policy
                        </h1>
                        <p>
                            Enter an otherwordly forest of sound and light on planet Nocturne X in this <span
                            className="Text--emphasis">transformative
                            multi-sensory art experience</span>, where the alien environment reacts to your every touch
                            and
                            movement.
                        </p>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default PrivacyPage